import { Injectable } from '@angular/core';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { ServerResponse } from 'bungie-api-ts/common';
import {
  DestinyClass,
  DestinyCollectibleComponent,
  DestinyComponentType,
  DestinyInventoryComponent,
  DestinyInventoryItemDefinition, DestinyItemCategoryDefinition,
  DestinyItemPlugBase,
  DestinyItemSubType,
  DestinyItemType,
  DestinyManifest,
  DestinyManifestComponentName,
  DestinyPlugSetDefinition,
  DestinyPowerCapDefinition,
  DestinyProfileResponse,
  DestinySeasonDefinition,
  DestinyStat,
  DestinyVendorFilter,
  DestinyVendorsResponse,
  equipItem,
  getDestinyManifest,
  getProfile,
  getVendors,
  ItemState,
  pullFromPostmaster,
  setItemLockState,
  TierType,
  transferItem
} from 'bungie-api-ts/destiny2';
import { from, NEVER, Observable, of, Subject, throwError } from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './auth.service';
import { BungieApiResponse, DestinyItemComponentCharacterized, InventoryBucket, RecipesCraftableState } from '../types';
import { CoreService, ManifestLoadingState } from './core.service';
import { ChecklistTracker } from '../checklist/checklist.types';
import { HttpClient } from '@angular/common/http';
import { DestinyItemCategory } from './destinyItemCategory';
import { ItemHelper } from '../common/item.helper';
import {
  DestinyArmorAppraisal,
  DestinyArmorAppraisalRating,
  DestinyRecipesItem,
  DestinyRecipesItemHelper
} from '../types/destiny-recipes-item';
import {
  getGroupsForMember,
  getMembersOfGroup,
  GroupsForMemberFilter,
  GroupType,
  RuntimeGroupMemberType
} from 'bungie-api-ts/groupv2';
import {
  DestinyItemInstanceEnergy,
  DestinyObjectiveDefinition,
  DestinyObjectiveProgress
} from 'bungie-api-ts/destiny2/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { logError } from '../console';
import { ToastrService } from 'ngx-toastr';
import { ObjectiveHash } from '../../utils/known-values';
import { DestinyArmorAppraiser } from '../types/destiny-armor-appraiser';
import { CoreSettingsConfiguration, getCommonSettings } from 'bungie-api-ts/core';
import { ItemCategoryHashes, PlugCategoryHashes } from '@Utils/generated-enums';
import { environment } from "@Env";
import { ClarityDatabase } from "@Types/clarity";

export enum UpdateStatus {
  UPDATED= 'UPDATED',
  UPTODATE = 'UPTODATE',
  ERROR = 'ERROR',
  CACHED = 'CACHED',
  LOADING = 'LOADING',
}

export interface ItemFilter {
  'inventory.bucketTypeHash'?: InventoryBucket;
  itemType?: DestinyItemType;
  'inventory.tierType'?: TierType;
  classType?: DestinyClass;
  [key: string]: any;
}

export interface ItemAdvancedFilters {
  maxPowerCap?: number;
  category?: DestinyItemCategory[];
  searchTerm?: string;
  inCategory?: DestinyItemCategory[];
  fields?: {[key: string]: any};
  intrinsicPerkHash?: number;
  minPowerCap?: number;
  notType?: DestinyItemCategory[];
  lootAfter?: string;
  hasRandomRoll?: boolean;
  categories?: DestinyItemCategory[];
}

export enum DataBaseType {
  NATIVE,
  INVENTORY
}

export interface DataSearchResult<T = any> {
  values: T[];
  offset: number;
  limit: number;
  totalSize: number;
  totalPages: number;
  currentPage: number;
}

export interface DestinyGearAssetsDefinition {
  content: Array<{
    dye_index_set: {
      geometry: number[];
      textures: number[];
    };
    female_index_set: {
      geometry: number[];
      textures: number[];
    };
    male_index_set: {
      geometry: number[];
      textures: number[];
    };
    geometry: string[];
    platform: string;
    textures: string[];
  }>;
  gear: string[];
}

@Injectable({providedIn: 'root'})
export class DataService {

  private static STORED_INVENTORY_KEY = 'inventoryData';
  private static STORED_INVENTORY_KEY_DATE = 'inventoryDataUpdate';

  language;
  manifest: any;
  powerCaps: DestinyPowerCapDefinition[];
  globalDestinySettings: CoreSettingsConfiguration;
  itemSubTypeDefinitionMapping: {[key in DestinyItemSubType]?: DestinyItemCategoryDefinition};
  weaponLevelText: string;

  supportedManifestLanguageMapping = {
    en: 'en',
    de: 'de',
    es: 'es',
    'es-mx': 'es-mx',
    fr: 'fr',
    it: 'it',
    ja: 'ja',
    ko: 'ko',
    pl: 'pl',
    'pt-br': 'pt-br',
    ru: 'ru',
    'zh-chs': 'zh-chs',
    'zh-cht': 'zh-cht',
    'sr-cs': 'en',
    nb: 'en',
    ar: 'en'
  };
  inventory: DestinyRecipesItem[];
  inventoryData: Partial<DestinyProfileResponse>;
  charactersIds: string[];
  manifestCategoryWhiteList = [
    'DestinyClassDefinition',
    'DestinyMilestoneDefinition',
    'DestinyActivityDefinition',
    'DestinyInventoryBucketDefinition',
    'DestinyObjectiveDefinition',
    'DestinyRaceDefinition',
    'DestinyGenderDefinition',
    'DestinyStatGroupDefinition',
    'DestinyItemCategoryDefinition',
    'DestinyDamageTypeDefinition',
    'DestinyStatDefinition',
    'DestinyVendorDefinition',
    'DestinyItemTierTypeDefinition',
    'DestinyPlugSetDefinition',
    'DestinyPowerCapDefinition',
    'DestinyProgressionDefinition',
    'DestinyInventoryItemDefinition',
    'DestinySeasonDefinition',
    'DestinyEnergyTypeDefinition',
    'DestinyCollectibleDefinition',
    'DestinySeasonPassDefinition',
    'DestinyPresentationNodeDefinition'
  ];

  currentClanId: string;
  clanMemberIds: string[];
  currentSeason: DestinySeasonDefinition;

  latestManifestInfo: DestinyManifest;

  spiderLoader$ = new Subject();
  spiderStock;
  loadingInventory: boolean;

  lastUpdate: string;
  lastUpdateStatus: UpdateStatus = UpdateStatus.LOADING;

  allPatterns: DestinyInventoryItemDefinition[];
  enhancedTraitsToNormalTraits = {};
  clarityDatabase: ClarityDatabase;

  get owner(): string {
    if (!this.inventoryData) { return 'Unauthentified user'; }
    return this.inventoryData.profile.data.userInfo.bungieGlobalDisplayName;
  }

  get ownerId(): string {
    return this.inventoryData.profile.data.userInfo.membershipId;
  }

  get ownerCode(): number {
    if (!this.inventoryData) { return -1; }
    return this.inventoryData.profile.data.userInfo.bungieGlobalDisplayNameCode;
  }

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected db: NgxIndexedDBService,
    protected cookies: CookieService,
    protected coreService: CoreService,
    protected translateService: TranslateService,
    protected toaster: ToastrService
  ) {
    if (this.coreService.hasOption('global.lang')) {
      this.setLanguage(this.coreService.getOption('global.lang'));
    } else if (typeof window !== 'undefined' && window.hasOwnProperty('navigator')) {
      this.setLanguage(window.navigator.language.slice(0, 2));
    } else {
      this.setLanguage('en');
    }
  }

  cacheInventory() {
    if (this.db && this.inventoryData) {
      this.db.update('inventory', { id: this.authService.currentMemberShip.membershipId, value: this.inventoryData }).subscribe({ error: (err) => console.error(err) });
    }
  }

  getCachedInventory(): Observable<{id: string, value: DestinyProfileResponse}> {
    if (this.db) {
      return this.db.getByKey('inventory', this.authService.currentMemberShip.membershipId);
    }
    return of(null);
  }

  exportData(options?: any) {
    return JSON.stringify({date: Date.now(), inventory: this.inventoryData, ...options});
  }

  setLanguage(locale: string) {
    this.language = locale;
    if (!this.supportedManifestLanguageMapping.hasOwnProperty(this.language)) {
      this.language = 'en';
    }
    try {
      this.translateService.use(this.language)
        .subscribe({
          next: () => this.coreService.setOption('global.lang', this.language),
          error: (err) => {
            console.error(err);
            this.toaster.error('Could not load language ' + this.language, '', { timeOut: 5000 });
            this.setLanguage('en');
          }
        });
    } catch (err) {
      this.setLanguage('en');
    }
  }

  loadCommonSettings() {
    return from(getCommonSettings(this.coreService.httpClient)).pipe(
      map((response) => {
        this.globalDestinySettings = response.Response;
        return this.globalDestinySettings;
      })
    );
  }

  loadManifest() {
    this.coreService.manifestLoadSub.next(ManifestLoadingState.LOADING);

    return from(this.db.getByKey<any>('manifest', 'latest')).pipe(
      mergeMap((manifest) => {
        return this.checkManifest(manifest)
          .pipe(
            mergeMap((manifestInfo) => {
              if (!manifestInfo) {
                return of(manifest.value);
              } else {
                return this.downloadManifest(manifestInfo);
              }
            })
          );
      }),
      map((manifest) => this.initManifest(manifest)),
      tap(() => this.coreService.manifestLoadSub.next(ManifestLoadingState.LOADED))
    );
  }

  checkManifest(currentManifest: any) {
    return from(getDestinyManifest(this.coreService.httpClient)).pipe(
      map((manifestInfo) => {
        this.latestManifestInfo = manifestInfo.Response;
        if (!currentManifest || manifestInfo.Response.version !== currentManifest.version || currentManifest.lang !== this.language) {
          return manifestInfo; // Language and version check
        } else if (!this.manifestCategoryWhiteList.every((category) => currentManifest.value.hasOwnProperty(category))) {
          return manifestInfo; // Integrity check: some category are missing
        }
        return null;
      })
    );
  }

  downloadManifest(manifestInfo: ServerResponse<DestinyManifest>) {
    this.coreService.manifestLoadSub.next(ManifestLoadingState.DOWNLOADING);
    return this.http.get<any>('https://www.bungie.net' + manifestInfo.Response.jsonWorldContentPaths[this.supportedManifestLanguageMapping[this.language]]).pipe(
      map((loaded) => {
        const manifest = _.pick(loaded, this.manifestCategoryWhiteList);
        this.db.update('manifest', { id: 'latest', value: manifest, version:  manifestInfo.Response.version, lang: this.supportedManifestLanguageMapping[this.language] })
          .subscribe(
            () => console.log('Manifest updated'),
            (err) => logError(err)
          );
        return manifest;
      })
    );
  }

  initManifest(man) {
    this.manifest = man;
    this.powerCaps = this.getDefinitions('DestinyPowerCapDefinition').values;
    this.weaponLevelText = this.getDefinitionById<DestinyObjectiveDefinition>('DestinyObjectiveDefinition', 3077315735)?.progressDescription;
    this.loadEnhancedTraits();
    this.loadClarity();
    this.itemSubTypeDefinitionMapping = {};
    if (this.manifest.DestinyItemCategoryDefinition) {
      Object.keys(this.manifest.DestinyItemCategoryDefinition).forEach((categoryIdx: string) => {
        const category = this.manifest.DestinyItemCategoryDefinition[categoryIdx];
        this.itemSubTypeDefinitionMapping[category.grantDestinySubType] = category;
      });
    }
    return this.manifest;
  }

  loadEnhancedTraits() {
    if (Object.keys(this.enhancedTraitsToNormalTraits).length === 0) {
      this.http.get(environment.recipesApi + '/public/traits.json').subscribe((traits) => {
        this.enhancedTraitsToNormalTraits = traits || {};
      });
    }
  }

  loadClarity() {
    if (!this.clarityDatabase) {
      this.http.get<ClarityDatabase>(environment.recipesApi + '/public/clarity.json').subscribe((db) => {
        this.clarityDatabase = db || {};
      });
    }
  }

  getSubTypeDefinition(subType: DestinyItemSubType): DestinyItemCategoryDefinition {
    return this.itemSubTypeDefinitionMapping[subType];
  }

  getDefinitions<T = any>(category: DestinyManifestComponentName, options: ItemFilter = {}, pagination: {limit: number, offset: number}= {limit: -1, offset: 0}, advancedFilters?: ItemAdvancedFilters): DataSearchResult<T> {
    const out: DataSearchResult = {
      offset: pagination.offset,
      limit: pagination.limit,
    } as DataSearchResult;
    const results = Object.keys(this.manifest[category])
      .filter((itemHash) => Object.keys(options).filter((o) => Boolean(options[o])).every((option) => _.get(this.manifest[category][itemHash], option) === options[option]))
      .filter((itemHash) => advancedFilters ? this.applyAdvancedFilters({ definition: this.manifest[category][itemHash] } as DestinyRecipesItem, advancedFilters) : true);
    out.totalSize = results.length;
    out.totalPages = Math.ceil(results.length / pagination.limit);
    out.values = results.slice(pagination.offset, pagination.limit < 0 ? Number.POSITIVE_INFINITY : pagination.offset + pagination.limit)
      .map((itemHash) => this.manifest[category][itemHash]);
    out.currentPage = out.offset % out.limit + 1;
    return out;
  }

  getDefinitionById<T = any>(category: DestinyManifestComponentName, hash: number): T {
    return this.manifest?.[category]?.[hash];
  }

  loadCachedInventory(): Observable<boolean> {
    return this.getCachedInventory().pipe(
      map((cached) => {
        if (cached?.value && cached.id === this.authService.currentMemberShip.membershipId) {
          this.lastUpdateStatus = UpdateStatus.CACHED;
          this.setInventoryData(cached.value);
          return true;
        }
        return false;
      }),
      catchError((err) => {
        console.error(err);
        return of(false);
      }),
    );
  }

  getInventory(force?: boolean) {
    if (this.loadingInventory) { return NEVER; }
    this.loadingInventory = true;
    this.lastUpdateStatus = UpdateStatus.LOADING;
    let emptyCache$: Observable<boolean | BungieApiResponse<any>> = of(true);
    if (force && this.inventory) {
      const fakeItem = this.inventory.find((e) => e.definition?.itemCategoryHashes?.indexOf(DestinyItemCategory.GHOST_SHELL) > -1);
      emptyCache$ = this.setItemLockState(fakeItem, ItemHelper.isLocked(fakeItem)).pipe(catchError(() => of(null)));
    }
    return emptyCache$.pipe(
      mergeMap(() => from(getProfile(this.coreService.httpClient, {
          membershipType: this.authService.currentMemberShip.membershipType,
          destinyMembershipId: this.authService.currentMemberShip.membershipId,
          components: [
            DestinyComponentType.CharacterEquipment,
            DestinyComponentType.CharacterInventories,
            DestinyComponentType.CharacterProgressions,
            DestinyComponentType.Characters,
            DestinyComponentType.Collectibles,
            DestinyComponentType.ProfileCurrencies,
            DestinyComponentType.ItemInstances,
            DestinyComponentType.ProfileInventories,
            DestinyComponentType.ProfileProgression,
            DestinyComponentType.Profiles,
            DestinyComponentType.ItemPerks,
            DestinyComponentType.ItemStats,
            DestinyComponentType.ItemSockets,
            DestinyComponentType.ItemPlugObjectives,
            DestinyComponentType.ItemReusablePlugs,
            DestinyComponentType.ItemPlugStates,
            DestinyComponentType.ItemObjectives,
            DestinyComponentType.Craftables,
            DestinyComponentType.Vendors,
            DestinyComponentType.VendorReceipts,
            DestinyComponentType.VendorSales,
            DestinyComponentType.VendorCategories,
          ]
        }))
      ),
      catchError((err) => {
        this.loadingInventory = false;
        this.lastUpdateStatus = UpdateStatus.ERROR;
        return throwError(err);
      }),
      filter((e) => Boolean((e))),
      map((result) => result.Response),
      tap((profileResponse: DestinyProfileResponse) => {
        this.loadingInventory = false;
        this.lastUpdateStatus = UpdateStatus.UPTODATE;
        if (force || !this.inventoryData || new Date(this.inventoryData?.responseMintedTimestamp) < new Date(profileResponse.responseMintedTimestamp)) {
          this.lastUpdateStatus = UpdateStatus.UPDATED;
          this.setInventoryData(profileResponse);
          this.getAllPatterns();
          this.loadVendor(this.charactersIds[0]).pipe(
            map((vendors) => vendors.sales?.data[2255782930]?.saleItems),
            tap((result) => this.spiderLoader$.next(result))
          ).subscribe();
        }
      }),
    );
  }

  setInventoryData(profileResponse: DestinyProfileResponse) {
    if (this.lastUpdateStatus !== UpdateStatus.CACHED) {
      this.lastUpdate = profileResponse.responseMintedTimestamp;
    }
    this.inventoryData = profileResponse;
    this.inventory = [];
    const list: DestinyItemComponentCharacterized[] = [...profileResponse.profileInventory.data.items, ...profileResponse.profileCurrencies.data?.items ?? []];
    this.inventory.push(...list.map((item) => ({...item, definition: this.getDefinitionById('DestinyInventoryItemDefinition', item.itemHash)} as DestinyRecipesItem)));
    Object.keys(profileResponse.characterEquipment.data).forEach((char) => {
      const charList: DestinyItemComponentCharacterized[] = [...profileResponse.characterEquipment.data[char].items, ...profileResponse.characterInventories.data[char].items];
      this.inventory.push(...charList.map((item) => ({...{...item, character: char}, definition: this.getDefinitionById('DestinyInventoryItemDefinition', item.itemHash)} as DestinyRecipesItem)));
    });
    this.inventory.forEach((item) => {
      try {
        item.isWeapon = ItemHelper.isWeapon(item.definition);
        item.isArmor = ItemHelper.isArmor(item.definition);
        item.stats = this.getItemStats(item.itemInstanceId);
        item.masterworked = (item.state & ItemState.Masterwork) !== 0;
        item.checkedAttributes = {};
        if (item.isWeapon) {
          item.crafted = (item.state & ItemState.Crafted) !== 0;
          item.deepsight = (item.state & ItemState.HighlightedObjective) !== 0;
          item.recipeState = this.getCraftableStateOfWeapon(item);
          if (item.crafted) {
            item.crafting = this.getWeaponShapeInfo(item);
          }
          item.objectives = this.inventoryData.itemComponents.objectives.data[item.itemInstanceId];
          item.plugObjectives = this.inventoryData.itemComponents.plugObjectives?.data[item.itemInstanceId];
          if (item.definition.traitIds.indexOf('weapon_type.glaive') > -1) {
            item.definition.itemCategoryHashes.push(DestinyItemCategory.W_GLAIVE);
            (item.definition as any).itemSubType = DestinyItemSubType.Glaive;
          }
        }
        this.computeItemPerks(profileResponse, item);
        if (item.isWeapon || item.isArmor) {
          item.powerLevel = this.getItemPowerLevel(item.itemInstanceId);
        }
        if (item.isArmor && item.stats) {
          item.armorAppraisal = this.getArmorAppraisal(item);
          item.classType = item.definition.classType;
          item.armorPieceType = DestinyRecipesItemHelper.getArmorPieceType(item);
          item.energy = this.getItemEnergy(item);
          item.armorTotal = Object.keys(item.stats.stats)
            .filter((statHash: string) => ![3578062600 /* Any Energy Type */, 2399985800 /* Void Cost */, 3344745325 /* Solar Cost */, 3779394102 /* Arc Cost */].includes(parseInt(statHash, 10)))
            .reduce((acc, cur) => acc + item.stats.stats[cur].value, 0);
          this.getBaseStats(item);
          if (item.baseStats) {
            item.baseArmorTotal = Object.keys(item.baseStats.stats).reduce((acc, cur) => acc + item.baseStats.stats[cur].value, 0);
          }
        }
      } catch (err) {
        item.hasError = true;
        logError(err);
      }
    });
    this.charactersIds = Object.keys(profileResponse.characterInventories.data);
    this.currentSeason = this.getDefinitionById<DestinySeasonDefinition>('DestinySeasonDefinition', profileResponse.profile.data.currentSeasonHash);
    this.cacheInventory();
  }

  getArmorAppraisal(item: DestinyRecipesItem): DestinyArmorAppraisal {
    const sockets = this.inventoryData.itemComponents.sockets.data[item.itemInstanceId];
    if (!sockets) { return DestinyArmorAppraiser.NotApplicableRating; }
    const stats = { intrinsic: {} };
    if (item.definition.investmentStats?.length > 0) {
      item.definition.investmentStats.forEach((stat) => {
        stats[stat.statTypeHash] = stat.value;
        stats.intrinsic[stat.statTypeHash] = stat.value;
      });
    }
    sockets.sockets.forEach((s) => {
      const socket = this.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', s.plugHash);
      if (socket?.plug?.plugCategoryHash === PlugCategoryHashes.Intrinsics && socket.investmentStats?.length && !socket.displayProperties.name) {
        socket.investmentStats.forEach((stat) => {
          if (!stats[stat.statTypeHash]) {
            stats[stat.statTypeHash] = 0;
          }
          stats[stat.statTypeHash] += stat.value;
        });
      }
    });
    if (item.definition.itemSubType === DestinyItemSubType.ClassArmor) {
      return DestinyArmorAppraiser.ClassItemAppraisal;
    }
    return (new DestinyArmorAppraiser(stats)).exec();
  }

  getSegmentShape(stats: {[key: number]: number}, statHashes: number[]): DestinyArmorAppraisalRating {
    const segmentStats = statHashes.map((statHash) => stats[statHash] || 0);
    const max = Math.max(...segmentStats);
    const min = Math.min(...segmentStats);
    if (min === 2 && max >= 20) { return DestinyArmorAppraisalRating.A; }
    if (min >= 6 && min <= 12 && max >= 20) { return DestinyArmorAppraisalRating.B; }
    if (min >= 6 && min <= 12 && max >= 13 && max <= 19) { return DestinyArmorAppraisalRating.C; }
    return DestinyArmorAppraisalRating.D;
  }

  getItemEnergy(item: DestinyRecipesItem): DestinyItemInstanceEnergy {
    return this.inventoryData.itemComponents?.instances?.data[item.itemInstanceId]?.energy;
  }

  getBaseStats(item: DestinyRecipesItem) {
    const investments = {};
    if (item.definition?.sockets?.socketCategories) {
      const socketEntriesIndexes = item.definition.sockets.socketCategories.find((e) => e.socketCategoryHash === 590099826); // get armor sockets entered
      socketEntriesIndexes?.socketIndexes.map((socketIdx) => {
        const plug = item.currentPlugs?.sockets[socketIdx];
        if (!plug?.isEnabled) {
          return;
        }
        const modApplied = this.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', plug.plugHash);
        if (modApplied.investmentStats?.length > 0) {
          modApplied.investmentStats
            .filter((investment) => ![3578062600 /* Any Energy Type */, 2399985800 /* Void Cost */, 3344745325 /* Solar Cost */, 3779394102 /* Arc Cost */].includes(investment.statTypeHash))
            .forEach((investment) => {
              if (!investment.isConditionallyActive && investment.value > 0) {
                if (!investments[investment.statTypeHash]) {
                  investments[investment.statTypeHash] = 0;
                }
                investments[investment.statTypeHash] += investment.value;
              } else if (investment.isConditionallyActive) {
                // TODO handle conditional plugs
              }
            });
        }
      });
    }
    const baseStats: { [key: number]: DestinyStat } = {};
    if (item.stats) {
      Object.keys(item.stats.stats)
        .filter((statHash: string) => ![3578062600, 2399985800, 3344745325, 3779394102].includes(parseInt(statHash, 10)))
        .forEach((statHash) => {
          baseStats[statHash] = {...item.stats.stats[statHash]};
          if (item.masterworked) {
            baseStats[statHash].value -= 2;
          }
          if (investments[statHash]) {
            baseStats[statHash].value -= investments[statHash];
          }
        });
    }
    item.baseStats = { stats: baseStats };
  }

  getCollectible(hash: number) {
    return this.inventoryData.profileCollectibles.data.collectibles[hash];
  }

  getCharacter(instanceId: string) {
    if (!this.inventoryData) {return null; }
    return this.inventoryData.characters.data[instanceId];
  }

  getObjective(hash: string) {
    return this.inventoryData.itemComponents.objectives.data[hash];
  }

  private getItemComponentInstance(instanceHash: string) {
    return this.inventoryData.itemComponents?.instances?.data[instanceHash];
  }

  private getItemStats(instanceHash: string) {
    return this.inventoryData.itemComponents?.stats?.data[instanceHash];
  }

  private getItemPowerLevel(instanceHash: string) {
    return this.inventoryData.itemComponents?.instances?.data[instanceHash].primaryStat?.value || 0;
  }

  private getInstancePlugs(instanceHash: string) {
    return this.inventoryData.itemComponents?.sockets?.data[instanceHash];
  }

  filterInventory(filters: ItemAdvancedFilters) {
    if (!this.inventory) { return []; }
    return this.inventory.filter((item) => new ItemFilterHelper(item, this).exec(filters));
  }

  loadVendor(characterId: string): Observable<DestinyVendorsResponse> {
    return from(getVendors(this.coreService.httpClient, {
      membershipType: this.authService.currentMemberShip.membershipType,
      destinyMembershipId: this.authService.currentMemberShip.membershipId,
      characterId,
      filter: DestinyVendorFilter.None,
      components: [DestinyComponentType.VendorCategories, DestinyComponentType.Vendors, DestinyComponentType.VendorSales]
    })).pipe(map((response) => response.Response));
  }

  getSpiderStock(): Observable<any> {
    if (this.spiderStock) { return of(this.spiderStock); }
    return this.spiderLoader$;
  }

  getClanId(forceRefresh?: boolean) {
    if (this.currentClanId && !forceRefresh) { return of (this.currentClanId); }
    return from(getGroupsForMember(this.coreService.httpClient, {
      membershipId: this.authService.currentMemberShip.membershipId,
      membershipType: this.authService.currentMemberShip.membershipType,
      groupType: GroupType.Clan,
      filter: GroupsForMemberFilter.All
    })).pipe(
      map((result) => {
        const response = result.Response;
        if (!response || !response.areAllMembershipsInactive || Object.keys(response.areAllMembershipsInactive).length === 0) {
          return null;
        } else {
          const validClans = Object.keys(response.areAllMembershipsInactive).filter((e) => !response.areAllMembershipsInactive[e]);
          if (validClans.length === 0) {
            return null;
          }
          return validClans[0];
        }
      }),
      tap((clanId) => this.currentClanId = clanId)
    );
  }

  setItemLockState(item: DestinyRecipesItem, locked: boolean): Observable<BungieApiResponse<any>> {
    return from(setItemLockState(this.coreService.httpClient, {
      characterId: item.character || this.charactersIds[0],
      itemId: item.itemInstanceId,
      membershipType: this.authService.currentMemberShip.membershipType,
      state: locked
    }).then((response) => {
      if (response.Message === 'Ok' || response.Message === 'OK') {
        if (locked) {
          (item as any).state |= ItemState.Locked;
        } else {
          (item as any).state &= ~ItemState.Locked;
        }
      }
      return response;
    }));
  }

  transferItem(item: DestinyRecipesItem, options: {toVault?: boolean, characterId?: string}): Observable<BungieApiResponse<any>> {
    return from(transferItem(this.coreService.httpClient, {
      characterId: options.characterId || item.character,
      itemId: item.itemInstanceId,
      itemReferenceHash: item.itemHash,
      membershipType: this.authService.currentMemberShip.membershipType,
      stackSize: 1,
      transferToVault: options.toVault === true
    }));
  }

  equipItem(item: DestinyRecipesItem, characterId: string): Observable<BungieApiResponse<any>> {
    return from(equipItem(this.coreService.httpClient, {
      characterId,
      itemId: item.itemInstanceId,
      membershipType: this.authService.currentMemberShip.membershipType,
    }));
  }

  pullItemFromPostmaster(item: DestinyRecipesItem): Observable<BungieApiResponse<any>> {
    return from(pullFromPostmaster(this.coreService.httpClient, {
      itemReferenceHash: item.itemHash,
      stackSize: 1,
      characterId: item.character,
      itemId: item.itemInstanceId,
      membershipType: this.authService.currentMemberShip.membershipType,
    }));
  }

  getCharacterEquipment(characterId: string): DestinyInventoryComponent {
    return this.inventoryData.characterEquipment?.data[characterId];
  }

  getCharacterInventory(characterId: string): DestinyInventoryComponent {
    return this.inventoryData.characterInventories?.data[characterId];
  }

  getCharacterInventoryForSlot(characterId: string, bucketHash: number): DestinyRecipesItem[] {
    const inventory = this.getCharacterInventory(characterId)?.items;
    if (!inventory) {
      return [];
    }
    return inventory.filter((e) => e.bucketHash === bucketHash).map((i) => this.inventory.find((ri) => ri.itemInstanceId === i.itemInstanceId));
  }

  setEquipment(item: DestinyRecipesItem, characterId: string) {
    const indexToReplace = this.getCharacterEquipment(characterId).items.findIndex((i) => i.bucketHash === item.bucketHash);
    this.getCharacterEquipment(characterId).items[indexToReplace] = item;
  }

  isItemEquipped(item: DestinyRecipesItem) {
    if (!item || !item.character) {
      return false;
    }
    return this.getCharacterEquipment(item.character)?.items.findIndex((i) => i.itemInstanceId === item.itemInstanceId) > -1;
  }

  getCraftableStateOfWeapon(item: DestinyRecipesItem): RecipesCraftableState {
    if (item.definition.inventory.recipeItemHash && this.inventoryData.characterCraftables) {
      const charKey = Object.keys(this.inventoryData.characterCraftables.data)[0];
      const craftable = this.inventoryData.characterCraftables.data[charKey].craftables?.[item.definition.inventory.recipeItemHash];
      if (craftable) {
        return {
          craftable: true,
          unlocked: craftable.visible && !craftable.failedRequirementIndexes?.length
        };
      }
    }
    return { craftable: false };
  }

  getWeaponShapeInfo(item: DestinyRecipesItem): { date: number; level: number; progress: number; } {
    if (item.crafted && this.inventoryData.itemComponents.plugObjectives) {
      const objective = this.inventoryData.itemComponents.plugObjectives.data[item.itemInstanceId];
      if (objective && objective.objectivesPerPlug[ObjectiveHash.shapedWeapon]) {
        const mapped: {[key: number]: DestinyObjectiveProgress} = {};
        objective.objectivesPerPlug[ObjectiveHash.shapedWeapon].forEach((o) => mapped[o.objectiveHash] = o);
        return {
          date: mapped[ObjectiveHash.weaponCraftingDate]?.progress,
          level: mapped[ObjectiveHash.weaponLevel]?.progress,
          progress: mapped[ObjectiveHash.weaponLevelProgression]?.progress / (mapped[ObjectiveHash.weaponLevelProgression]?.completionValue ?? 1),
        };
      }
    }
    return undefined;
  }

  async getClanMembers() {
    if (this.clanMemberIds) { return this.clanMemberIds; }
    let page = 1;
    let hasMore = true;
    const members = [];
    while (hasMore) {
      const result = await getMembersOfGroup(this.coreService.httpClient, { currentpage: page, groupId: this.currentClanId, memberType: RuntimeGroupMemberType.None, nameSearch: '' });
      hasMore = result.Response.hasMore;
      page++;
      members.push(...result.Response.results);
    }
    this.clanMemberIds = [];
    members.forEach((m) => {
      if (m.destinyUserInfo) {
        this.clanMemberIds.push(m.destinyUserInfo.membershipId);
      }
      if (m.bungieNetUserInfo) {
        this.clanMemberIds.push(m.bungieNetUserInfo.membershipId);
      }
    });
    return this.clanMemberIds;
  }

  findCollectibleState(collectibleHash: number): DestinyCollectibleComponent {
    if (!this.inventoryData?.profileCollectibles?.data) {
      return;
    }
    const collectible = this.inventoryData.profileCollectibles.data.collectibles[collectibleHash];
    if (collectible) {
      return collectible;
    }
    if (!this.inventoryData?.characterCollectibles?.data) {
      return;
    }
    return Object.keys(this.inventoryData.characterCollectibles.data).map((charId) => {
      return this.inventoryData.characterCollectibles.data[charId].collectibles[collectibleHash];
    }).filter((e) => !!e)?.[0];
  }

  purge() {
    this.inventory = null;
    this.inventoryData = null;
    this.currentClanId = null;
    if (this.db) {
      this.db.delete('inventory', this.authService.currentMemberShip.membershipId).subscribe();
    }
  }

  // UTILS

  getAllPatterns(): void {
    this.allPatterns = Object.keys(this.manifest.DestinyInventoryItemDefinition)
      .map((key) => this.manifest.DestinyInventoryItemDefinition[key])
      .filter((item) => item?.itemCategoryHashes?.includes(ItemCategoryHashes.Patterns));
  }

  defaultTrackerResolver(tracker: ChecklistTracker): DestinyRecipesItem {
    const item = this.inventory ? this.inventory
      .filter((e) => e.itemHash === tracker.itemHash)
      .reduce((acc, cur) => {
        if (!acc) {
          acc = { ...cur };
        } else {
          (acc as any).quantity += cur.quantity;
        }
        return acc;
      }, null) : undefined;
    return item || {
      quantity: 0,
      definition: this.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', tracker.itemHash)
    } as DestinyRecipesItem;
  }

  private applyAdvancedFilters(item: DestinyRecipesItem, filters: ItemAdvancedFilters): boolean {
    const itemFilter = new ItemFilterHelper(item, this);
    return itemFilter.exec(filters);
  }

  private computeItemPerks(profileResponse: DestinyProfileResponse, item: DestinyRecipesItem) {
    item.currentPlugs = this.getInstancePlugs(item.itemInstanceId);
    if (item.isWeapon) {
      const perksIndex = item.definition.sockets?.socketCategories?.find((cat) => cat.socketCategoryHash === 4241085061)?.socketIndexes;
      item.curated = true;
      if (perksIndex) {
        item.reusablePlugs = {plugs: {}};
        perksIndex.forEach((perkIndex) => {
          // Check if instance have this perk
          const instancePerks = profileResponse.itemComponents.reusablePlugs?.data[item.itemInstanceId]?.plugs[perkIndex];
          if (instancePerks) {
            item.reusablePlugs.plugs[perkIndex] = instancePerks;
            item.curated = false;
            return;
          }

          // If not, check if definition has a PlugSet
          const plugSetHash = item.definition.sockets.socketEntries[perkIndex]?.reusablePlugSetHash;
          if (plugSetHash) {
            const plugSet = this.getDefinitionById<DestinyPlugSetDefinition>('DestinyPlugSetDefinition', plugSetHash);
            item.reusablePlugs.plugs[perkIndex] = plugSet.reusablePlugItems.map((plug) => ({plugItemHash: plug.plugItemHash} as any));
            return;
          }

          // If not, check if definition has PlugItems
          if (item.definition.sockets.socketEntries[perkIndex]?.reusablePlugItems) {
            item.reusablePlugs.plugs[perkIndex] = item.definition.sockets.socketEntries[perkIndex]?.reusablePlugItems as any;
            return;
          }
        });

        item.perks = perksIndex
          .filter((plugSetIndex) => !!item.reusablePlugs.plugs[plugSetIndex])
          .map((plugSetIndex) => item.reusablePlugs.plugs[plugSetIndex].map((plug: DestinyItemPlugBase) => ({
              plugHash: plug.plugItemHash,
              enhanced: this.getDefinitionById<DestinyInventoryItemDefinition>('DestinyInventoryItemDefinition', plug.plugItemHash).inventory.tierType === TierType.Common,
              active: item.currentPlugs?.sockets[plugSetIndex].plugHash === plug.plugItemHash
            }))
          );
      }
    }
  }
}

class ItemFilterHelper {
  item: DestinyRecipesItem;
  dataService: DataService;

  constructor(item: DestinyRecipesItem, dataService: DataService) {
    this.item = item;
    this.dataService = dataService;
  }

  exec(filters: ItemAdvancedFilters): boolean {
    return Object.keys(filters).every((rule: keyof ItemAdvancedFilters) => this.applyRule(rule, filters[rule]));
  }

  /** If negative is true, returns the inverse */
  applyRule(rule: keyof ItemAdvancedFilters, filterValue: any) {
    switch (rule) {
      case 'maxPowerCap':
        return this.powerCapFilter(filterValue);
      case 'minPowerCap':
        return this.powerCapFilterInverse(filterValue);
      case 'category':
        return this.categoryFilter(filterValue);
      case 'notType':
        return !this.categoryFilter(filterValue);
      case 'inCategory':
        return this.isInCategoryFilter(filterValue);
      case 'searchTerm':
        return this.partialNameFilter(filterValue);
      case 'fields':
        return this.fieldMatch(filterValue);
      case 'lootAfter':
        return this.lootAfter(filterValue);
      case 'intrinsicPerkHash':
        return this.hasIntrinsicPerk(filterValue);
      case 'hasRandomRoll':
        return this.hasRandomRoll(filterValue);
      default:
        return true;
    }
  }

  lootAfter(recencyIdentifier: string) {
    const recency = DestinyRecipesItemHelper.getItemRecencyIdentifier(this.item);
    return recency && recency > recencyIdentifier;
  }

  powerCapFilter(powerCap: number): boolean {
    if (!this.item || !this.item.definition.quality || !this.item.definition.quality.versions) {
      return false;
    }
    const powerCaps: DestinyPowerCapDefinition[] = this.dataService.getDefinitions('DestinyPowerCapDefinition').values;
    const compatiblePowerCaps = powerCaps.filter((cap) => cap.powerCap < 2000 && cap.powerCap >= powerCap).map((e) => e.hash);
    if (!compatiblePowerCaps || !compatiblePowerCaps.length) {
      return false;
    }
    return compatiblePowerCaps.includes(this.item.definition.quality.versions[!isNaN(this.item.versionNumber) ? this.item.versionNumber : this.item.definition.quality.currentVersion].powerCapHash);
  }

  powerCapFilterInverse(powerCap: number): boolean {
    if (!this.item || !this.item.definition.quality || !this.item.definition.quality.versions) {
      return false;
    }
    const powerCaps: DestinyPowerCapDefinition[] = this.dataService.getDefinitions('DestinyPowerCapDefinition').values;
    const compatiblePowerCaps = powerCaps.filter((cap) => cap.powerCap >= powerCap).map((e) => e.hash);
    if (!compatiblePowerCaps || !compatiblePowerCaps.length) {
      return true;
    }
    return !compatiblePowerCaps.includes(this.item.definition.quality.versions[!isNaN(this.item.versionNumber) ? this.item.versionNumber : this.item.definition.quality.currentVersion].powerCapHash);
  }

  partialNameFilter(term: string): boolean {
    if (this.item.definition?.displayProperties?.name) {
      return this.item.definition.displayProperties.name.toLowerCase().indexOf(term.toLowerCase()) > -1;
    } else {
      return false;
    }
  }

  categoryFilter(categories: number[]): boolean {
    if (!categories) { return false; }
    return categories.every((cat) => this.item.definition?.itemCategoryHashes?.includes(cat));
  }

  isInCategoryFilter(categories: number[]): boolean {
    return _.intersection(this.item.definition?.itemCategoryHashes, categories)?.length > 0;
  }

  fieldMatch(value: {[key: string]: any}): boolean {
    return Object.keys(value).every((key) => this.item[key] === value[key]);
  }

  hasIntrinsicPerk(value: number): boolean {
    return ItemHelper.getIntrinsicPerkHash(this.item.definition) === value;
  }

  hasRandomRoll(value: boolean): boolean {
    return ItemHelper.hasRandomRoll(this.item) === value;
  }


}
